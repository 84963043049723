// pdfHandler.ts
import html2pdf from 'html2pdf.js'; // Make sure to install html2pdf.js using npm or yarn
import apiBackend from '@/service/api-insurance';

export function exportToPDF(filename: string): void {
  const element = document.getElementById('element-to-convert');
  if (element) {
    element.style.width = '1620px';
    html2pdf(element, {
      margin: 10,
      filename,
      html2canvas: {
        width: 1620,
      },
    }).then((response) => {
      element.style.width = ''; // Reset width style
    });
  }
}
export function saveZusammenfassungPDF(id: number, apiBackendMethod: string, onSubmitCallback?: () => void): void {
  const element = document.getElementById('element-to-convert');
  if (element) {
    element.style.width = '1620px';
    const opt = {
      margin: 10,
      html2canvas: {
        width: 1620,
      },
    };
    html2pdf().set(opt).from(element).output('datauristring')
      .then((response) => {
        element.style.width = ''; // Reset width style
        const arr = response.split(',');
        const pfdStr = arr[1];
        const data = {
          insuranceId: id,
          pfdStr,
        };

        const apiMethods = {
          saveRiskPDF: apiBackend.saveRiskPDF,
          saveTarifPDF: apiBackend.saveTarifPDF,
          saveBasicPDF: apiBackend.saveBasicPDF,
          savePricePDF: apiBackend.savePDF,
        };

        const backendMethod = apiMethods[apiBackendMethod];
        if (backendMethod) {
          backendMethod(data).then(() => {
            if (onSubmitCallback) {
              onSubmitCallback();
            }
          });
        }
      });
  }
}
