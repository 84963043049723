export default [
  {
    name: 'Windenergieanlage',
    value: 1750
  },
  {
    name: 'Windenergieanlage Enercon mit VWK',
    value: 1753
  },
  {
    name: 'Windenergieanlage Senvion mit VWK',
    value: 1762
  },
  {
    name: 'Windenergieanlage jünger 16 Jahre mit Getriebe',
    value: 1781
  },
  {
    name: 'Windenergieanlage jünger 16 Jahre ohne Getriebe',
    value: 1782
  },
  {
    name: 'Windenergieanlage ab 16 Jahre mit Getriebe',
    value: 1783
  },
  {
    name: 'Windenergieanlage ab 16 Jahre ohne Getriebe',
    value: 1784
  },
];
